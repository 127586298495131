<template>
  <v-card>
    <v-card-text>
      <v-row>
        <v-col  cols="12" sm="12" md="12">

         <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=991 style='width:743.4pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;height:9.0pt'>
        <td width=156 style='width:117.05pt;padding:0in 0in 0in 0in;height:
        9.0pt'></td>
        <td width=835 style='width:626.35pt;padding:0in 0in 0in 0in;height:
        9.0pt;min-width: 220.94mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;height:.5in'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        height:.5in;min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.5in'>
          <td width=991 valign=top style='width:743.4pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.5in;min-width: 262.22mm'>
          <p class=MsoNormal align=center style='text-align:center'><b><i><span
          style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Weekly Report on ATI Requests - Active and to be Closed<br>
          Rapport hebdomadaire sure les demandes d'accès à l'information -
          actives et à être completées<o:p></o:p></span></i></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:2;height:.25in'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=991 valign=top style='width:743.4pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in;min-width: 262.22mm'>
          <p class=MsoNormal align=center style='text-align:center'><b><i><span
          style='font-size:14.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>November 06, 2020 to November 28, 2020 / du 06 novembre
          au 28 novembre 2020<o:p></o:p></span></i></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:3;height:9.0pt'>
        <td width=991 colspan=2 style='width:743.4pt;padding:0in 0in 0in 0in;
        height:9.0pt;min-width: 262.22mm'></td>
       </tr>
       <tr style='mso-yfti-irow:4;height:.25in'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=991 valign=top style='width:743.4pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in;min-width: 262.22mm'>
          <p class=MsoNormal><b><i><span style='font-size:11.0pt;mso-fareast-font-family:
          "Times New Roman";color:blue'>LEGEND:<o:p></o:p></span></i></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:5;height:.25in'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=991 valign=top style='width:743.4pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in;min-width: 262.22mm'>
          <p class=MsoNormal><span style='font-size:11.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Total active ATI Requests: 13 (pages
          under review - 2,226) <o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:6;height:.25in'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=991 valign=top style='width:743.4pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in;min-width: 262.22mm'>
          <p class=MsoNormal><span style='font-size:11.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Total completed ATI Requests to date:
          0 (pages reviewed/released = / )<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:7;height:.25in'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=991 valign=top style='width:743.4pt;padding:2.0pt 0in 2.0pt 2.0pt;
          height:.25in;min-width: 262.22mm'>
          <p class=MsoNormal><span style='font-size:11.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Disclosures provided MINO, DMO and CMB
          (others as required) 3 calendar-days prior to release.<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:8;height:1.0pt'>
        <td width=991 colspan=2 style='width:743.4pt;padding:0in 0in 0in 0in;
        height:1.0pt;min-width: 262.22mm'></td>
       </tr>
       <tr style='mso-yfti-irow:9;height:.25in'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=991 valign=top style='width:743.4pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in;min-width: 262.22mm'>
          <p class=MsoNormal><span style='font-size:11.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>N/A represents cases on hold with the
          consent of the applicant<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:10;height:1.0pt'>
        <td width=991 colspan=2 style='width:743.4pt;padding:0in 0in 0in 0in;
        height:1.0pt;min-width: 262.22mm'></td>
       </tr>
       <tr style='mso-yfti-irow:11;height:.25in'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=991 valign=top style='width:743.4pt;padding:2.0pt 0in 2.0pt 2.0pt;
          height:.25in;min-width: 262.22mm'>
          <p class=MsoNormal><b><span style='font-size:11.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Bolded request # = 'Sensitive'.<o:p></o:p></span></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:12;height:.25in'>
        <td width=156 valign=top style='width:117.05pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 41.28mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;background:palegreen;
          padding:2.0pt 2.0pt 2.0pt 2.0pt;height:.25in;min-width: 41.28mm'>
          <p class=MsoNormal><span style='font-size:11.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>Green requests #<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
        <td width=835 valign=top style='width:626.35pt;padding:0in 0in 0in 0in;
        height:.25in;min-width: 220.94mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:
          yes;height:.25in'>
          <td width=835 valign=top style='width:626.35pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in;min-width: 220.94mm'>
          <p class=MsoNormal><span style='font-size:11.0pt;mso-fareast-font-family:
          "Times New Roman";color:black'>- partial response only<o:p></o:p></span></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:13;height:43.0pt'>
        <td width=991 colspan=2 style='width:743.4pt;padding:0in 0in 0in 0in;
        height:43.0pt;min-width: 262.22mm'></td>
       </tr>
       <tr style='mso-yfti-irow:14'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=991 style='width:743.4pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 262.22mm' cols=5>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=564 style='width:423.05pt;padding:0in 0in 0in 0in;
          min-width: 149.23mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.3in'>
          <td colspan=3>
          <p class=MsoNormal style='vertical-align:top'><b><u><span
          style='mso-fareast-font-family:"Times New Roman";color:black'>NEW
          REQUESTS - to (New = 0)<o:p></o:p></span></u></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Request No.<br>
          No. demande<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Received<br>
          Reçue<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Due Date<br>
          Échéance<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Closing<br>
          Complétée<o:p></o:p></span></i></b></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Source / Source<br>
          Subject / Sujet de la demande<br>
          Sectors involved / Secteurs impliqués<o:p></o:p></span></i></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:15;height:6.9pt'>
        <td width=991 colspan=2 style='width:743.4pt;padding:0in 0in 0in 0in;
        height:6.9pt;min-width: 262.22mm'></td>
       </tr>
       <tr style='mso-yfti-irow:16'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=991 style='width:743.4pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 262.22mm' cols=5>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=564 style='width:423.05pt;padding:0in 0in 0in 0in;
          min-width: 149.23mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.3in'>
          <td colspan=3>
          <p class=MsoNormal style='vertical-align:top'><b><u><span
          style='mso-fareast-font-family:"Times New Roman";color:black'>DISCLOSURES
          and ANTICIPATED DISCLOSURES - to (0 disclosures)<o:p></o:p></span></u></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;mso-yfti-lastrow:yes;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Request No.<br>
          No. demande<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Received<br>
          Reçue<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Due Date<br>
          Échéance<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Closing<br>
          Complétée<o:p></o:p></span></i></b></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Source / Source<br>
          Subject / Sujet de la demande<br>
          Sectors involved / Secteurs impliqués<o:p></o:p></span></i></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
       <tr style='mso-yfti-irow:17;height:16.1pt'>
        <td width=991 colspan=2 style='width:743.4pt;padding:0in 0in 0in 0in;
        height:16.1pt;min-width: 262.22mm'></td>
       </tr>
       <tr style='mso-yfti-irow:18;mso-yfti-lastrow:yes'>
        <td width=991 colspan=2 valign=top style='width:743.4pt;padding:0in 0in 0in 0in;
        min-width: 262.22mm'>
        <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
         width=991 style='width:743.4pt;border-collapse:collapse;mso-yfti-tbllook:
         1184;mso-padding-alt:0in 0in 0in 0in;min-width: 262.22mm' cols=5>
         <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
          <td></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
          <td width=564 style='width:423.05pt;padding:0in 0in 0in 0in;
          min-width: 149.23mm'></td>
         </tr>
         <tr style='mso-yfti-irow:1;height:.3in'>
          <td colspan=3>
          <p class=MsoNormal style='vertical-align:top'><b><u><span
          style='mso-fareast-font-family:"Times New Roman";color:black'>ACTIVE
          ATI REQUESTS (by request #) - 13 Requests<o:p></o:p></span></u></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:2;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Request No.<br>
          No. demande<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Received<br>
          Reçue<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Due Date<br>
          Échéance<o:p></o:p></span></i></b></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Closing<br>
          Complétée<o:p></o:p></span></i></b></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:blue'>Source / Source<br>
          Subject / Sujet de la demande<br>
          Sectors involved / Secteurs impliqués<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:3;height:.25in'>
          <td width=991 colspan=5 valign=top style='width:743.4pt;border:none;
          border-bottom:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><u><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>FY - 2018-19<o:p></o:p></span></u></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:4;height:15.1pt'>
          <td width=156 valign=top style='width:117.05pt;border:none;
          mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt;min-width: 41.28mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0001
          / ML<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;mso-border-top-alt:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-04-10<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;mso-border-top-alt:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-07-09<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;mso-border-top-alt:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;border:none;
          mso-border-top-alt:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:5;height:.3in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>All
          agreements, applications, contractual documents, or records relating
          to any agreement, application or contract between SDTC and Enerkem.<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:6;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:7;height:15.1pt'>
          <td width=156 valign=top style='width:117.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt;min-width: 41.28mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2017-00006
          / ML<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-04-13<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-06-12<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:8;height:.3in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>All
          briefing materials and memos prepared for the CEO of Sustainable
          Development Technology Canada in February 2018.<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:9;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:10;height:15.1pt'>
          <td width=156 valign=top style='width:117.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt;min-width: 41.28mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0005
          / ML<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-06-25<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:11;height:.3in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:12;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:13;height:15.1pt'>
          <td width=156 valign=top style='width:117.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt;min-width: 41.28mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0006
          / ML<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-05-26<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-06-25<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Public<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:14;height:.3in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:15;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:16;height:15.1pt'>
          <td width=156 valign=top style='width:117.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt;min-width: 41.28mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0007
          / ML<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-05-28<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-06-27<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Public<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:17;height:.3in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:18;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:19;height:15.1pt'>
          <td width=156 valign=top style='width:117.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt;min-width: 41.28mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0008
          / ML<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-06-04<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-07-04<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:20;height:.3in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:21;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Sectors/Secteurs: BUS,CB<o:p></o:p></span></i></b></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:22;height:15.1pt'>
          <td width=156 valign=top style='width:117.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt;min-width: 41.28mm'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0009
          / DG($)<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-08-09<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2018-11-22<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;border:none;border-top:
          solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;border:none;
          border-top:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:15.1pt'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:23;height:.3in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.3in'>
          <p class=MsoNormal style='vertical-align:top'><span style='font-size:
          10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>This is
          summary text<o:p></o:p></span></p>
          </td>
         </tr>
         <tr style='mso-yfti-irow:24;mso-yfti-lastrow:yes;height:.25in'>
          <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
          </td>
          <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
          height:.25in'>
          <p class=MsoNormal style='vertical-align:top'><b><i><span
          style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
          color:black'>Sectors/Secteurs: CIPO,IS<o:p></o:p></span></i></b></p>
          </td>
         </tr>
        </table>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in;min-width: 47.63mm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=991
     style='width:743.4pt;border-collapse:collapse;mso-yfti-tbllook:1184;
     mso-padding-alt:0in 0in 0in 0in' cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
      height:22.5pt'>
      <td width=180 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in;
       min-width: 47.63mm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><b><span style='font-size:8.0pt;mso-fareast-font-family:
        "Times New Roman";color:black'>Weekly ATI Request Report<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=216 valign=top style='width:2.25in;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 57.15mm'></td>
      <td width=180 valign=top style='width:135.0pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.62mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>2020-11-13<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=288 valign=top style='width:216.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 76.20mm'></td>
      <td width=127 valign=top style='width:95.3pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 33.62mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 25.40mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>1 of 2<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<div>

<div class=MsoNormal align=center style='text-align:center'><span
style='mso-fareast-font-family:"Times New Roman"'>

<hr size=2 width="100%" align=center>

</span></div>

</div>

<div>

<div>

<table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
 style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in;
 min-width: 262.22mm' cols=5>
 <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
  <td style='padding:0in 0in 0in 0in;min-width: 41.28mm'>
  <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
   style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in;
   min-width: 41.28mm'>
   <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
    <td style='padding:0in 0in 0in 0in'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
     style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes'>
      <td valign=top style='padding:0in 0in 0in 0in'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       width=991 style='width:743.4pt;border-collapse:collapse;mso-yfti-tbllook:
       1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes'>
        <td width=156 style='width:117.05pt;padding:0in 0in 0in 0in'></td>
        <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
        <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
        <td width=90 style='width:67.8pt;padding:0in 0in 0in 0in;min-width: 23.91mm'></td>
        <td width=564 style='width:423.05pt;padding:0in 0in 0in 0in;min-width: 149.23mm'></td>
       </tr>
       <tr style='mso-yfti-irow:1;height:.3in'>
        <td width=991 colspan=5 valign=top style='width:743.4pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal style='vertical-align:top'><b><u><span
        style='mso-fareast-font-family:"Times New Roman";color:black'>ACTIVE
        ATI REQUESTS (by request #) - 13 Requests<o:p></o:p></span></u></b></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:2;height:.25in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:blue'>Request No.<br>
        No. demande<o:p></o:p></span></i></b></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:blue'>Received<br>
        Reçue<o:p></o:p></span></i></b></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:blue'>Due Date<br>
        Échéance<o:p></o:p></span></i></b></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:blue'>Closing<br>
        Complétée<o:p></o:p></span></i></b></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 4.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:blue'>Source / Source<br>
        Subject / Sujet de la demande<br>
        Sectors involved / Secteurs impliqués<o:p></o:p></span></i></b></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:3;height:15.1pt'>
        <td width=156 valign=top style='width:117.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt;
        min-width: 41.28mm'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0013
        / DG<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2019-01-23<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>2019-02-22<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:4;height:.3in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>all
        correspondence<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:5;height:.25in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Sectors/Secteurs: CIPO,IS<o:p></o:p></span></i></b></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:6;height:.25in'>
        <td width=991 colspan=5 valign=top style='width:743.4pt;border:none;
        border-bottom:solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><u><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>FY - -1<o:p></o:p></span></u></b></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:7;height:15.1pt'>
        <td width=156 valign=top style='width:117.05pt;border:none;mso-border-top-alt:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt;
        min-width: 41.28mm'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0003
        / ML<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;mso-border-top-alt:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;mso-border-top-alt:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;mso-border-top-alt:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;border:none;mso-border-top-alt:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:8;height:.3in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>All
        briefing materials and memos prepared for the President &amp; CEO of
        SDTC for the month of March 2018<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:9;height:.25in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:10;height:15.1pt'>
        <td width=156 valign=top style='width:117.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt;
        min-width: 41.28mm'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0004
        / ML<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Media<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:11;height:.3in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>All
        briefing materials and memos prepared for the President &amp; CEO of
        SDTC for the month of April 2018<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:12;height:.25in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:13;height:15.1pt'>
        <td width=156 valign=top style='width:117.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt;
        min-width: 41.28mm'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0010
        / DG<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:14;height:.3in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:15;height:.25in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:16;height:15.1pt'>
        <td width=156 valign=top style='width:117.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt;
        min-width: 41.28mm'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-0011
        / <o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:17;height:.3in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:18;height:.25in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:19;height:15.1pt'>
        <td width=156 valign=top style='width:117.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt;
        min-width: 41.28mm'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>A-2018-12
        / <o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;border:none;border-top:
        solid black 1.0pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;height:15.1pt'>
        <p class=MsoNormal style='vertical-align:top'><span style='font-size:
        10.0pt;mso-fareast-font-family:"Times New Roman";color:black'>Business<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:20;height:.3in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.3in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
       </tr>
       <tr style='mso-yfti-irow:21;mso-yfti-lastrow:yes;height:.25in'>
        <td width=156 valign=top style='width:117.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=90 valign=top style='width:67.8pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'>&nbsp;<o:p></o:p></span></p>
        </td>
        <td width=564 valign=top style='width:423.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal style='vertical-align:top'><b><i><span
        style='font-size:10.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>Sectors/Secteurs: <o:p></o:p></span></i></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
   <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
    <td style='padding:0in 0in 0in 0in;min-width: 47.63mm'>
    <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0 width=991
     style='width:743.4pt;border-collapse:collapse;mso-yfti-tbllook:1184;
     mso-padding-alt:0in 0in 0in 0in' cols=5>
     <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
      height:22.5pt'>
      <td width=180 valign=top style='width:135.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.63mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in;
       min-width: 47.63mm'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in'>
        <p class=MsoNormal><b><span style='font-size:8.0pt;mso-fareast-font-family:
        "Times New Roman";color:black'>Weekly ATI Request Report<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=216 valign=top style='width:2.25in;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 57.15mm'></td>
      <td width=180 valign=top style='width:135.0pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 47.62mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=180 valign=top style='width:135.05pt;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 47.63mm'>
        <p class=MsoNormal align=center style='text-align:center'><b><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>2020-11-13<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
      <td width=288 valign=top style='width:216.05pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 76.20mm'></td>
      <td width=127 valign=top style='width:95.3pt;padding:0in 0in 0in 0in;
      height:22.5pt;min-width: 33.62mm'>
      <table class=MsoNormalTable border=0 cellspacing=0 cellpadding=0
       style='mso-cellspacing:0in;mso-yfti-tbllook:1184;mso-padding-alt:0in 0in 0in 0in'>
       <tr style='mso-yfti-irow:0;mso-yfti-firstrow:yes;mso-yfti-lastrow:yes;
        height:.25in'>
        <td width=96 valign=top style='width:1.0in;padding:2.0pt 2.0pt 2.0pt 2.0pt;
        height:.25in;min-width: 25.40mm'>
        <p class=MsoNormal align=right style='text-align:right'><b><span
        style='font-size:8.0pt;mso-fareast-font-family:"Times New Roman";
        color:black'>2 of 2<o:p></o:p></span></b></p>
        </td>
       </tr>
      </table>
      </td>
     </tr>
    </table>
    </td>
   </tr>
  </table>
  </td>
  <td width="100%" style='width:100.0%;padding:0in 0in 0in 0in'></td>
 </tr>
 <tr style='mso-yfti-irow:1;mso-yfti-lastrow:yes'>
  <td width=0 style='width:.3pt;padding:0in 0in 0in 0in'></td>
  <td style='padding:0in 0in 0in 0in'></td>
 </tr>
</table>

<p class=MsoNormal><span style='mso-fareast-font-family:"Times New Roman"'><o:p>&nbsp;</o:p></span></p>

</div>

</div>



         
        </v-col>

      </v-row>
    </v-card-text>
  </v-card>
</template>
<script>

//import { mapGetters, mapState} from "vuex";
import axcessApi from "@/plugins/axcessApi";
export default {
  props:['display','dateFrom','reportId'],
  created(){
    this.generateReport();
  },
  methods: {
    generateReport() {
      let data = {
        dateFrom: this.dateFrom,
        display:this.display,
        reportId:this.reportId

      }
      const response = axcessApi.postRequest("/report/Weekly",data);
      response.then(output => {
        console.log(output);
      })
    },
    selected() {
    }
  }
};
</script>