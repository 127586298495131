
<template>
  <div>
    <v-card>
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <v-select v-model="reqType"   @change="selected" :items="reqTypes" item-text="label" item-value="id" label="Choose Weekly Report"></v-select>
          </v-col>
        </v-row>


        <v-row v-if="reqType== 0 || reqType==1">
          <v-col cols="12" sm="6" md="6">
            <DatePicker v-model="dateFrom" label="Run Date"></DatePicker>
          </v-col>
     
          <v-col cols="12" sm="6" md="6">
            <v-select v-model="display" :items="displays" item-text="name" item-value="name" label="Report Display"></v-select>
          </v-col>
        </v-row>



        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="generateReport">{{$t('View Report')}}</v-btn>
        <v-btn v-if="reqType!= -1" color="primary" dark class="mb-2" @click="closeReport">{{$t('Close Report')}}</v-btn>


      </v-card-text>
    </v-card>
    <weeklyreport v-if="reqType === 0 && flag==1" :reportId="reqType" :dateFrom="dateFrom"  :display="display"></weeklyreport>
    <weeklyreportnoid v-if="reqType === 1 && flag==1" :reportId="reqType" :dateFrom="dateFrom"  :display="display"></weeklyreportnoid>

    <div v-else>
      <v-progress-circular
          v-if="flag==1"
          :size="50"
          color="primary"
          indeterminate
      ></v-progress-circular>
    </div>
  </div>
</template>

<script>

import DatePicker from "../components/DatePicker";
import weeklyreport from "../components/report/weeklyreport";
import weeklyreportnoid from "../components/report/weeklyreportnoid";

export default {
  created () {
  },
  components: {
    DatePicker,
    weeklyreport,
    weeklyreportnoid,

  },
  data(){
    return {
      flag:0,
      reqType:"-1",
      reqTypes: [
        {
          id:0,
          label: "Weekly Report",
        },
        {
          id:1,
          label: "Weekly Report No Id",
        },
      ],
      dateFrom: new Date().toISOString().substr(0, 10),
      dateTo: new Date().toISOString().substr(0, 10),
      displays:[
        { id:'0',name:"New disclosures Active"},
        { id:'1',name:"Disclosures New Active"}

      ],
      display:"0",

    };
  },
  methods:{
    selected(){

    },
    generateReport(){
      this.flag = 1;
    },
    closeReport(){
      this.flag = 0;
    }
  }
};

</script>


<style lang="scss" scoped>
table, th, td{
  width:1px !important;
 font-size: 1px !important;
 
}
</style>
